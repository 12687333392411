import { template as template_0536bdfbd2fc4613bc8b23c951c1d304 } from "@ember/template-compiler";
const FKLabel = template_0536bdfbd2fc4613bc8b23c951c1d304(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
