import { template as template_8ff95f5338a648f2aa8d6d8599390748 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8ff95f5338a648f2aa8d6d8599390748(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
